<template>
    <div class="transactions-general">
        <h1 class="transactions-header">{{ $t(headerText) }}</h1>
        <table v-if="items.length > 0" class="transactions-table">
            <tr class="transactions-table-header">
                <th v-for="header in headers" :key="header.id">{{ header }}</th>
            </tr>
            <template v-if="windowWidth > 515">
                <tr class="transactions-table-item-container" v-for="(row, index) in pages[page]" :key="index">
                    <td class="transactions-table-item" v-for="(value, key, index) in row" :key="index">
                        {{ value }}
                    </td>
                </tr>
            </template>
            <template v-else>
                <tr class="transactions-table-mobile" colspan="7" v-for="row in pages[page]" :key="row.transactionId">
                    <MobileView :row="row" :headers="headers" :isTransactions="isTransactions"/>
                </tr>
            </template>

            <td colspan="7" v-if="pages.length > 1">
                <div class="pagination">
                    <div class="pagination-item" @click="setPage(prevPage)">
                        <img src="@/components/assets/left.svg" alt="">
                    </div>
                    <div @click="setPage(index)" class="pagination-item" :class="page === index ? 'active' : ''"
                        v-for="(item, index) in pages" :key="index">
                        <span>{{ index + 1 }}</span>
                    </div>
                    <div class="pagination-item" @click="setPage(nextPage)">
                        <img src="@/components/assets/right.svg" alt="">
                    </div>
                </div>
            </td>
        </table>
        <table v-else class="transactions-table">
            <tr class="transactions-table-header">
                <th v-for="header in headers" :key="header.id">{{ header }}</th>
            </tr>
            <td colspan="7">
                <div class="transactions-table-empty">
                    <div class="empty-img">
                        <img src="@/components/assets/solar_card-search-outline.svg" alt="">
                    </div>
                    <h1>
                        {{ $t('generalTabs.noResults') }}
                    </h1>
                    <p>{{ $t('generalTabs.youDontHaveHistory') }}</p>
                    <button class="transactions-btn subscribe" @click="goToSubscriptionPage">{{
                        $t('generalTabs.subscribeNow') }}</button>
                </div>
            </td>
        </table>
    </div>
</template>

<script>

export default {
    props: {
        items: Array,
        headers: Array,
        isTransactions: Boolean,
        headerText: String,
        buttonText: String,
        helperText: String
    },
    computed: {
        prevPage() {
            return this.page - 1 !== -1 ? this.page - 1 : 0
        },
        nextPage() {
            return this.page + 1 !== this.pages.length ? this.page + 1 : this.pages.length - 1
        }
    },
    data() {
        return {
            page: 0,
            pages: [],
            windowWidth: window.innerWidth
        }
    },
    components: {
        MobileView: () => import('../PremiumClub/MobileView.vue')
    },
    methods: {
        paginationPages(array, pageSize) {
            const result = [];
            for (let i = 0; i < array.length; i += pageSize) {
                result.push(array.slice(i, i + pageSize));
            }
            this.pages = result
        },
        setPage(pageIndex) {
            this.page = pageIndex
        },
        goToSubscriptionPage() {
            this.$router.push('/Subscriptions');
        }
    },
    watch: {
        items(item) {
            if (this.windowWidth > 515) this.paginationPages(item, 10)
            else this.paginationPages(item, 3)
        }
    }
}
</script>

<style scoped>
h1,
h3,
p,
button,
input,
label,
a,
th,
td {
    font-family: 'Wix Madefor Display', sans-serif;
    color: #AAAFC1;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.64px;
    margin: 0;
}



.transactions-general {
    width: inherit;
    flex-direction: column;
    height: fit-content;
    background-color: rgba(31, 33, 39, .6);
    backdrop-filter: blur(10px);
    padding: 24px 32px;
    border-radius: 0 0 12px 12px;
}

.transactions-header {
    font-size: 20px;
    font-weight: 600;
    color: white;
}

.transactions-table {
    width: 100%;
    margin-top: 16px;
}

.transactions-table-header {
    background-color: #FFFFFF1A;
}

.transactions-table-header th {
    padding: 22px 16px;
}

.transactions-table-header th:first-child {
    border-radius: 8px 0 0 0;
}

.transactions-table-header th:last-child {
    border-radius: 0 8px 0 0;
}

.transactions-table-item {
    padding: 16px;
}

.transactions-table-item-container {
    border: 1px solid #FFFFFF1A;
}

.not-reflecting-header {
    font-size: 20px;
    line-height: 20.2px;
    color: white;
}

.transactions-btn {
    font-weight: 600;
    font-size: 14px;
    color: white;
    padding: 12px 16px;
    border-radius: 8px;
    background-color: #4E4BCD;
    margin-top: 16px;
}

.subscribe {
    margin-top: 24px;
}

.transactions-table-empty {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 110px 0;
    border: 1px solid #FFFFFF1A;
    border-radius: 0 0 8px 8px;
    text-align: center;
}

.transactions-table-empty h1 {
    font-size: 20px;
    color: white;
    line-height: 25.2px;
    margin-bottom: 8px;
}

.transactions-table-empty p {
    color: #FFFFFF66;
    font-weight: 400;
}

.empty-img {
    border-radius: 16px;
    padding: 16px;
    margin-bottom: 24px;
    background-color: #4E4BCD14;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
}

.pagination-item {
    padding: 5px 8px;
    border-radius: 4px;
    border: 1px solid #FFFFFF1A;
    background-color: #1F2127;
    cursor: pointer;
    margin-right: 8px;
}

.active {
    background-color: #4E4BCD;
    border: 1px solid #4E4BCD;
    color: white;
}

@media only screen and (max-width: 768px) {
    .transactions-table-header {
        display: none;
    }

    .transactions-table-empty {
        border: none;
        padding: 24px 0 16px 0;
    }
}
</style>